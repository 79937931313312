import styles from './project-item.module.scss'
import Github from '../../assets/github-2.png'
import Demo from '../../assets/demo.png'

const ProjectItem = ({ name, description, github, demo, technologies }) => (
  <div className={styles.project}>
    <div className={styles.project__image}>
      <img src="https://source.unsplash.com/random/400x100" alt="random" />
    </div>
    <div className={styles.project__title}>
      <h1 className={styles.header}>{name}</h1>
    </div>
    <div className={styles.project__subtitle}>
      <p className={styles.subtitle}>{description}</p>
    </div>
    <div className={styles.project__info}>
      <div className={styles.project__technologies}>
        <h3 className={styles.header}>Technologies</h3>
        <p className={styles.subtitle}>{technologies.join(', ')}</p>
      </div>
      <div className={styles.project__links}>
        <h3 className={styles.header}>Source / Demo</h3>
        <div className={styles.links}>
          <a
            href={github}
            target="_blank"
            rel="noopener noreferrer"
            className={styles.github}
          >
            <i className={styles.github__icon}>
              <img src={Github} alt="github" />
            </i>
          </a>
          <a
            href={demo}
            target="_blank"
            rel="noopener noreferrer"
            className={styles.demo}
          >
            <i className={styles.demo__icon}>
              <img src={Demo} alt="demo" />
            </i>
          </a>
        </div>
      </div>
    </div>
  </div>
)

export default ProjectItem
