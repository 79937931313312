import styles from './contact.module.scss'

const Contact = () => (
  <div className={styles.contact}>
    <div className={styles.contact__title}>
      <h1 className={styles.header}>Contact</h1>
      <p className={styles.subtitle}>
        You can send an e-mail for communication or questions
      </p>
      {/* <div className={styles.button}> */}
      <a href="mailto:elmalcanberk@gmail.com" className={styles.highlight}>
        Call to Action
      </a>
      {/* </div> */}
    </div>
  </div>
)

export default Contact
