import React, { useEffect } from 'react'
import { Link } from 'react-scroll'
import styles from './navbar.module.scss'

const Navbar = () => {
  useEffect(() => {
    const detectScroll = () => {
      if (window.scrollY > 0) {
        document
          .querySelector(`.${styles.navbar}`)
          .classList.add(`${styles.navbar__container__scrolled}`)
      } else {
        document
          .querySelector(`.${styles.navbar}`)
          .classList.remove(`${styles.navbar__container__scrolled}`)
      }
    }
    window.addEventListener('scroll', detectScroll)
    return () => {
      window.removeEventListener('scroll', detectScroll)
    }
  }, [])
  return (
    <div className={styles.navbar}>
      <div className={styles.navbar__menu}>
        <ul>
          <li>
            <Link to="top" smooth={true}>
              About
            </Link>
          </li>
          <li>
            <Link to="projects" smooth={true}>
              Projects
            </Link>
          </li>
          <li>
            <Link to="contact" smooth={true}>
              Contact
            </Link>
          </li>
        </ul>
      </div>
    </div>
  )
}

export default Navbar
