export const PROJECTS_DATA = [
  {
    id: 1,
    name: 'Project 1',
    description: 'Description of Project 1',
    github: 'https://github.com/bradtraversy/bsi',
    demo: '',
    image: 'https://via.placeholder.com/150',
    technologies: ['React', 'Node', 'Express', 'MongoDB'],
  },
  {
    id: 2,
    name: 'Project 2',
    description: 'Description of Project 2',
    github: 'https://github.com/bradtraversy/bsi',
    demo: '',
    image: 'https://via.placeholder.com/150',
    technologies: ['React', 'Node', 'Express', 'MongoDB'],
  },
  {
    id: 3,
    name: 'Project 3',
    description: 'Description of Project 3',
    github: 'https://github.com/bradtraversy/bsi',
    demo: '',
    image: 'https://via.placeholder.com/150',
    technologies: ['React', 'Node', 'Express', 'MongoDB'],
  },
  {
    id: 4,
    name: 'Project 4',
    description: 'Description of Project 4',
    github: 'https://github.com/bradtraversy/bsi',
    demo: '',
    image: 'https://via.placeholder.com/150',
    technologies: ['React', 'Node', 'Express', 'MongoDB'],
  },
]
