import styles from './projects.module.scss'
import ProjectItem from '../project-item/project-item.component'

const Projects = ({ data }) => (
  <div className={styles.projects}>
    <div className={styles.projects__wrapper}>
      <h1 className={styles.header}>Projects</h1>
      <div className={styles.project__items}>
        {data.map(({ id, ...otherProps }) => (
          <ProjectItem key={id} {...otherProps} />
        ))}
      </div>
    </div>
  </div>
)

export default Projects
